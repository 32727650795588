import React, { useState, useEffect, useContext } from "react";
import Loading from '../components/Loading';
import { capitalize } from '../util/anyFunctions';
import * as bootstrap from 'bootstrap';
import ToastModal from "../components/ToastModal";
import $ from 'jquery';
import UserContext from "../context/useLoginContext";
import useCategoryList from '../hooks/useCategoryList';
import useTagList from '../hooks/useTagList';
import Select from 'react-select';
import upload from '@img/upload.webp';
import trash from '@img/trash.webp';
import facebook from '@img/facebook.webp';
import instagram from '@img/instagram.webp';
import whatsapp from '@img/whatsapp.webp';
import CreatableSelect from 'react-select/creatable';
import GoogleMapReact from 'google-map-react';
import myMarker from '@img/my-marker.webp';
import logo from '@img/logo.webp';
import marker from '@img/marker.webp';
import { saveService, storeById } from '../api/index';
import ModalResponse from "../components/ModalResponse";
import '@styles/NewServices.scss';

const NewServices = ({storeId}) => {
    const [loading, setLoading] = useState(true);
    const [isdashPage, setIsdashPage] = useState(true);
    const [category, setCategory] = useState(null);
    const [tag, setTag] = useState(null);
    const [tags, setTags] = useState(null);
    const [categories, setCategories] = useState(null);
    const [nameStore, setNameStore] = useState(null);
    const [typeStore, setTypeStore] = useState(null);
    const [descriptionStore, setDescriptionStore] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [linkStore, setLinkStore] = useState(null);
    const [fbStore, setFbStore] = useState(null);
    const [instragamStore, setInstragamStore] = useState(null);
    const [whatsappStore, setWhatsappStore] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [message, setMessage] = useState(null);
    const [messageToast, setMessageToast] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [markerIdCounter, setMarkerIdCounter] = useState(0);
    const [imageUrl, setImageUrl] = useState(null);
    const [hasPermitions, setHasPermitions] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const { user } = useContext(UserContext);
    const [imgsDeleted, setImgsDeleted] = useState([])

    const [photo1, setPhoto1] = useState(null);
    const [photo2, setPhoto2] = useState(null);
    const [photo3, setPhoto3] = useState(null);
    const [photo4, setPhoto4] = useState(null);
    const [photo5, setPhoto5] = useState(null);
    const [photo6, setPhoto6] = useState(null);
    const [photo7, setPhoto7] = useState(null);
    const [photo8, setPhoto8] = useState(null);
    const [photo9, setPhoto9] = useState(null);
    const [photo10, setPhoto10] = useState(null);

    const [imageName1, setImageName1] = useState(null);
    const [imageName2, setImageName2] = useState(null);
    const [imageName3, setImageName3] = useState(null);
    const [imageName4, setImageName4] = useState(null);
    const [imageName5, setImageName5] = useState(null);
    const [imageName6, setImageName6] = useState(null);
    const [imageName7, setImageName7] = useState(null);
    const [imageName8, setImageName8] = useState(null);
    const [imageName9, setImageName9] = useState(null);
    const [imageName10, setImageName10] = useState(null);

    const [imageUrl1, setImageUrl1] = useState(null);
    const [imageUrl2, setImageUrl2] = useState(null);
    const [imageUrl3, setImageUrl3] = useState(null);
    const [imageUrl4, setImageUrl4] = useState(null);
    const [imageUrl5, setImageUrl5] = useState(null);
    const [imageUrl6, setImageUrl6] = useState(null);
    const [imageUrl7, setImageUrl7] = useState(null);
    const [imageUrl8, setImageUrl8] = useState(null);
    const [imageUrl9, setImageUrl9] = useState(null);
    const [imageUrl10, setImageUrl10] = useState(null);

    const regex = /\/([^\/?]+\.webp)/;

    const defaultProps = {
        center: { lat: -12.085845, lng: -76.977371 },
        zoom: 14
    };

    const fetchData = async () => {
        try {
            if (user) {
                await processDataSearch();
            }
        } catch (error) {
            console.error("Error fetching store when user is logged:", error);
            setMessageToast("¡Lo sentimos! En este momento no hay establecimientos disponibles para la busqueda seleccionada.")
            if (messageToast) {
                const toastEl = $('#liveToast')[0];
                if (toastEl) {
                    let toast = new bootstrap.Toast(toastEl, { delay: 5000 });
                    toast.show();
                }
            }
            setLoading(false);
        }
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error("Error getting user location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    useEffect(() => {
        fetchData();
        getUserLocation();
    }, [user]);

    const processDataSearch = async() =>{
        setLoading(true);
        if(storeId){
            const response = await storeById({storeId});
            setHasPermitions(response?.hasPermitions)
            setNameStore(response?.data?.store?.name);
            setDescriptionStore(response?.data?.store?.description);
            setLinkStore(response?.data?.store?.web)
            setFbStore(response?.data?.store?.facebookLink)
            setInstragamStore(response?.data?.store?.instagramLink)
            setWhatsappStore(response?.data?.store?.whatsapp)
            setImageName(response?.data?.store?.image)
            setImageUrl(response?.data?.store?.image)
            setIsAvailable(response?.data?.store?.isAvailable)
            setIsValidated(response?.data?.store?.isValidated)
            setImageUrl1(response?.data?.store?.gallery[0]?.photo)
            setImageName1(response?.data?.store?.gallery[0]?.photo)
            setImageUrl2(response?.data?.store?.gallery[1]?.photo)
            setImageName2(response?.data?.store?.gallery[1]?.photo)
            setImageUrl3(response?.data?.store?.gallery[2]?.photo)
            setImageName3(response?.data?.store?.gallery[2]?.photo)
            setImageUrl4(response?.data?.store?.gallery[3]?.photo)
            setImageName4(response?.data?.store?.gallery[3]?.photo)
            setImageUrl5(response?.data?.store?.gallery[4]?.photo)
            setImageName5(response?.data?.store?.gallery[4]?.photo)
            setImageUrl6(response?.data?.store?.gallery[5]?.photo)
            setImageName6(response?.data?.store?.gallery[5]?.photo)
            setImageUrl7(response?.data?.store?.gallery[6]?.photo)
            setImageName7(response?.data?.store?.gallery[6]?.photo)
            setImageUrl8(response?.data?.store?.gallery[7]?.photo)
            setImageName8(response?.data?.store?.gallery[7]?.photo)
            setImageUrl9(response?.data?.store?.gallery[8]?.photo)
            setImageName9(response?.data?.store?.gallery[8]?.photo)
            setImageUrl10(response?.data?.store?.gallery[9]?.photo)
            setImageName10(response?.data?.store?.gallery[9]?.photo)
            let categories = []
            response?.data?.store?.categories.forEach((element) => {
                categories.push({label: element.name, value: element.value})
            });
            setCategory(categories)
            setTypeStore({label:capitalize(response?.data?.store?.type), value: response?.data?.store?.type})
            let tags = []
            response?.data?.store?.tags.forEach((element) => {
                tags.push({label: element.name, value: element.id})
            });
            setTag(tags)
            setMarkerIdCounter(response?.data?.store?.locations.length)
            let markers_ = []
            response?.data?.store?.locations.forEach((element, index) => {
                markers_.push({id: index, lat: element.lat, lng: element.lng, address: element.reference})
            });
            setMarkers(markers_)

        }
        const [categoriesResponse, tagsResponse] = await Promise.all([
            useCategoryList('all'),
            useTagList()
        ]);
        setTags(tagsResponse.tags)
        setCategories(categoriesResponse.categories);
        setLoading(false);
        setIsdashPage(true)
    }

    const handleChange = async(selectedOption) => {
        setCategory(selectedOption)
    };

    const handleChangeTypeStore = async(selectedOption) => {
        setTypeStore(selectedOption)
    };

    const handleChangeTag = async(selectedOption) => {
        setTag(selectedOption)
    };

    const handleChangeNameStore = (event) => {
        setNameStore(event.target.value);
    };

    const handleChangedescriptionStore = (event) => {
        if(event.target.value.length > 2500){
            setMessageToast("¡Lo sentimos! La descripción no puede ser mayor a 600 caracteres.")
            const toastEl = $('#liveToast')[0];
            if (toastEl) {
                let toast = new bootstrap.Toast(toastEl, { delay: 5000 });
                toast.show();
            }
        }else{
            setDescriptionStore(event.target.value);
        }
    };

    const handleChangelinkStore = (event) => {
        setLinkStore(event.target.value);
    };

    const handleChangeAddress = (event, id) => {
        const newMarkers = markers.map((marker)=>{
            if(marker.id === id){
                marker.address = event.target.value
            }
            return marker;
        })
        setMarkers(newMarkers)
    };

    const handleChangefbStore = (event) => {
        setFbStore(event.target.value);
    };

    const handleChangeinstagramStore = (event) => {
        setInstragamStore(event.target.value);
    };

    const handleChangewhatsappStore = (event) => {
        setWhatsappStore(event.target.value);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto(file);
            setImageName(file.name);
            setImageUrl(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const handleFileChange1 = (event) => {
        const file = event.target.files[0];
        if (file.size > 2 * 1024 * 1024) { // 2 MB en bytesalert("El tamaño del archivo debe ser menor a 2 MB.");
            alert("Lo siento, el tamaño de la imagen supera los 2MB")
            return; // No continuar si el archivo es demasiado grande
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto1(file);
            setImageName1(file.name);
            setImageUrl1(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const validateS3UrlImage= (url) =>{
        return url.includes('https://s3.amazonaws.com/firulife.img/store')
    }

    const deleteImage1 = () => {
        validateS3UrlImage(imageUrl1) && setImgsDeleted([...imgsDeleted, ("store/"+imageUrl1.match(regex)[1])])
        setPhoto1(null);
        setImageName1(null);
        setImageUrl1(null);
    }

    const handleFileChange2 = (event) => {
        const file = event.target.files[0];
        if (file.size > 2 * 1024 * 1024) { // 2 MB en bytesalert("El tamaño del archivo debe ser menor a 2 MB.");
            alert("Lo siento, el tamaño de la imagen supera los 2MB")
            return; // No continuar si el archivo es demasiado grande
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto2(file);
            setImageName2(file.name);
            setImageUrl2(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const deleteImage2 = () => {
        validateS3UrlImage(imageUrl2) && setImgsDeleted([...imgsDeleted, ("store/"+imageUrl2.match(regex)[1])])
        setPhoto2(null);
        setImageName2(null);
        setImageUrl2(null);
    }

    const handleFileChange3 = (event) => {
        const file = event.target.files[0];
        if (file.size > 2 * 1024 * 1024) { // 2 MB en bytesalert("El tamaño del archivo debe ser menor a 2 MB.");
            alert("Lo siento, el tamaño de la imagen supera los 2MB")
            return; // No continuar si el archivo es demasiado grande
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto3(file);
            setImageName3(file.name);
            setImageUrl3(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const deleteImage3 = () => {
        validateS3UrlImage(imageUrl3) && setImgsDeleted([...imgsDeleted, ("store/"+imageUrl3.match(regex)[1])])
        setPhoto3(null);
        setImageName3(null);
        setImageUrl3(null);
    }

    const handleFileChange4 = (event) => {
        const file = event.target.files[0];
        if (file.size > 2 * 1024 * 1024) { // 2 MB en bytesalert("El tamaño del archivo debe ser menor a 2 MB.");
            alert("Lo siento, el tamaño de la imagen supera los 2MB")
            return; // No continuar si el archivo es demasiado grande
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto4(file);
            setImageName4(file.name);
            setImageUrl4(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const deleteImage4 = () => {
        validateS3UrlImage(imageUrl4) && setImgsDeleted([...imgsDeleted, ("store/"+imageUrl4.match(regex)[1])])
        setPhoto4(null);
        setImageName4(null);
        setImageUrl4(null);
    }

    const handleFileChange5 = (event) => {
        const file = event.target.files[0];
        if (file.size > 2 * 1024 * 1024) { // 2 MB en bytesalert("El tamaño del archivo debe ser menor a 2 MB.");
            alert("Lo siento, el tamaño de la imagen supera los 2MB")
            return; // No continuar si el archivo es demasiado grande
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto5(file);
            setImageName5(file.name);
            setImageUrl5(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const deleteImage5 = () => {
        validateS3UrlImage(imageUrl5) && setImgsDeleted([...imgsDeleted, ("store/"+imageUrl5.match(regex)[1])])
        setPhoto5(null);
        setImageName5(null);
        setImageUrl5(null);
    }

    const handleFileChange6 = (event) => {
        const file = event.target.files[0];
        if (file.size > 2 * 1024 * 1024) { // 2 MB en bytesalert("El tamaño del archivo debe ser menor a 2 MB.");
            alert("Lo siento, el tamaño de la imagen supera los 2MB")
            return; // No continuar si el archivo es demasiado grande
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto6(file);
            setImageName6(file.name);
            setImageUrl6(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const deleteImage6 = () => {
        validateS3UrlImage(imageUrl6) && setImgsDeleted([...imgsDeleted, ("store/"+imageUrl6.match(regex)[1])])
        setPhoto6(null);
        setImageName6(null);
        setImageUrl6(null);
    }

    const handleFileChange7 = (event) => {
        const file = event.target.files[0];
        if (file.size > 2 * 1024 * 1024) { // 2 MB en bytesalert("El tamaño del archivo debe ser menor a 2 MB.");
            alert("Lo siento, el tamaño de la imagen supera los 2MB")
            return; // No continuar si el archivo es demasiado grande
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto7(file);
            setImageName7(file.name);
            setImageUrl7(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const deleteImage7 = () => {
        validateS3UrlImage(imageUrl7) && setImgsDeleted([...imgsDeleted, ("store/"+imageUrl7.match(regex)[1])])
        setPhoto7(null);
        setImageName7(null);
        setImageUrl7(null);
    }

    const handleFileChange8 = (event) => {
        const file = event.target.files[0];
        if (file.size > 2 * 1024 * 1024) { // 2 MB en bytesalert("El tamaño del archivo debe ser menor a 2 MB.");
            alert("Lo siento, el tamaño de la imagen supera los 2MB")
            return; // No continuar si el archivo es demasiado grande
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto8(file);
            setImageName8(file.name);
            setImageUrl8(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const deleteImage8 = () => {
        validateS3UrlImage(imageUrl8) && setImgsDeleted([...imgsDeleted, ("store/"+imageUrl8.match(regex)[1])])
        setPhoto8(null);
        setImageName8(null);
        setImageUrl8(null);
    }

    const handleFileChange9 = (event) => {
        const file = event.target.files[0];
        if (file.size > 2 * 1024 * 1024) { // 2 MB en bytesalert("El tamaño del archivo debe ser menor a 2 MB.");
            alert("Lo siento, el tamaño de la imagen supera los 2MB")
            return; // No continuar si el archivo es demasiado grande
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto9(file);
            setImageName9(file.name);
            setImageUrl9(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const deleteImage9 = () => {
        validateS3UrlImage(imageUrl9) && setImgsDeleted([...imgsDeleted, ("store/"+imageUrl9.match(regex)[1])])
        setPhoto9(null);
        setImageName9(null);
        setImageUrl9(null);
    }

    const handleFileChange10 = (event) => {
        const file = event.target.files[0];
        if (file.size > 2 * 1024 * 1024) { // 2 MB en bytesalert("El tamaño del archivo debe ser menor a 2 MB.");
            alert("Lo siento, el tamaño de la imagen supera los 2MB")
            return; // No continuar si el archivo es demasiado grande
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto10(file);
            setImageName10(file.name);
            setImageUrl10(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const deleteImage10 = () => {
        validateS3UrlImage(imageUrl10) && setImgsDeleted([...imgsDeleted, ("store/"+imageUrl10.match(regex)[1])])
        setPhoto10(null);
        setImageName10(null);
        setImageUrl10(null);
    }

    const handleMapClick = (event) => {
        const newMarker = {
            id: markerIdCounter,
            lat: event.lat,
            lng: event.lng
        };

        setMarkers([...markers, newMarker]);
        setMarkerIdCounter(markerIdCounter + 1);
    };

    const onChildClick = async(_key, props) =>{
        const id = props.id;
        const updatedMarkers = markers.filter(marker => marker.id !== id);
        setMarkers(updatedMarkers);
    }

    const MyPositionMarker = () => (
        <div style={{
            width: '22px',
            height: '22px',
            backgroundImage: `url(${myMarker})`,
            backgroundSize: 'cover',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
        </div>
    );

    const MarkerMap = () => (
        <div style={{
            width: '44px',
            height: '44px',
            backgroundImage: `url(${marker})`,
            backgroundSize: 'cover',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop:"-44px",
            marginLeft:"-22px",
            cursor:"pointer",
        }}>
        </div>
    );

    const handleSwitchChangeAvailable = (event) => {
        setIsAvailable(event.target.checked);
    };

    const handleSwitchChangeValidated = (event) => {
        setIsValidated(event.target.checked);
    };

    const saveStore = async(event) => {
        event.preventDefault();
        let formData = new FormData();
        if(storeId){
            formData.append('storeId', storeId);
            formData.append('isAvailable', isAvailable);
            formData.append('isValidated', isValidated);
        }
        if(
            !category || category?.length === 0 || !nameStore || !descriptionStore ||
            !typeStore || !tag || tag?.length === 0 || markers.length === 0
        ){
            setMessageToast("Por favor completar todos los campos requeridos.")
            const toastEl = $('#liveToast')[0];
            if (toastEl) {
                let toast = new bootstrap.Toast(toastEl, { delay: 5000 });
                toast.show();
            }
        }else{
            if(category?.length > 0){
                category.forEach((element) => {
                    formData.append('categories', element.value);
                });
            }
            if(imgsDeleted.length > 0 ){ formData.append('imgsDeleted', imgsDeleted) }
            if(nameStore){ formData.append('name', nameStore) }
            if(descriptionStore){ formData.append('description', descriptionStore) }
            if(photo){ formData.append('photo', photo) }
            if (photo1){ formData.append('gallery1', photo1) }
            if (photo2){ formData.append('gallery2', photo2) }
            if (photo3){ formData.append('gallery3', photo3) }
            if (photo4){ formData.append('gallery4', photo4) }
            if (photo5){ formData.append('gallery5', photo5) }
            if (photo6){ formData.append('gallery6', photo6) }
            if (photo7){ formData.append('gallery7', photo7) }
            if (photo8){ formData.append('gallery8', photo8) }
            if (photo9){ formData.append('gallery9', photo9) }
            if (photo10){ formData.append('gallery10', photo10) }
            if(typeStore){ formData.append('type', typeStore.value) }
            if(linkStore){ formData.append('linkStore', linkStore) }
            if(fbStore){ formData.append('fbStore', fbStore) }
            if(instragamStore){ formData.append('instragamStore', instragamStore) }
            if(whatsappStore){ formData.append('whatsappStore', whatsappStore) }
            if(tag?.length > 0){
                tag.forEach((element) => {
                    if (typeof element.value === 'string') {
                        formData.append('tag', element.value.toUpperCase());
                    }else{
                        formData.append('tag', element.value);
                    }
                });
            }
            if(markers?.length > 0){
                markers.forEach((element) => {
                    formData.append('markers', JSON.stringify(element));
                });
            }

            setIsdashPage(false)
            setLoading(true);
            const response = await saveService(formData)
            setLoading(false);
            setIsdashPage(true)

            if(response.status !== "fail"){
                if(storeId){
                    setMessage("Los cambios en el servicio han sido guardados satisfactoriamente")
                }else{
                    setMessage("Analizaremos la información y agregaremos el servicio solicitado, luego te responderemos via correo electrónico")
                }
                $('#response-modal').modal('show');
            }else{
                setLoading(false);
                setIsdashPage(true)
                setMessageToast("¡Lo sentimos!, hubo un error al intentar actualizar la información.")
                const toastEl = $('#liveToast')[0];
                if (toastEl) {
                    let toast = new bootstrap.Toast(toastEl, { delay: 6000 });
                    toast.show();
                    navigate('/');
                }
            }
        }
    };


    return (
        <div className="NewServices">
            <div className='Content'>
                <div className="title">Registrar Servicio</div>
                <form id="form-service">
                    <div className="content-service">
                        <div className="subtitle">¿Que tipo de servicio deseas agregar?</div>
                        <label className="label">Categoría</label>
                        <Select
                            name="categories"
                            isMulti
                            value={category}
                            className="select-input"
                            classNamePrefix="select-input"
                            options={categories}
                            placeholder=""
                            defaultValue={category}
                            onChange={handleChange}
                        />
                        <div className="border-bottom"></div>
                        <div className="subtitle">Detalle del establecimiento</div>
                        <label className="label" htmlFor="validationStoreName">Nombre</label>
                        <div className="input">
                            <input type="text"
                                onChange={handleChangeNameStore}
                                name="nameStore"
                                id="validationStoreName"
                                value={nameStore || ''}
                                required
                            />
                        </div>
                        <label className="label" htmlFor="validationStoreDescription">Descripción</label>
                        <div className="input">
                            <textarea
                                onChange={handleChangedescriptionStore}
                                name="descriptionStore"
                                id="validationStoreDescription"
                                value={descriptionStore || ''}
                                required
                            ></textarea>
                            <span>** Max. 2500 caracteres</span>
                        </div>
                        <label className="label">Foto</label>
                        {imageUrl && <img
                            src={imageUrl} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload" className="custom-file-upload">
                                {imageName && imageName.substr(0,39)+'...'}
                                <img src={upload}/>
                            </label>
                            <input name="chagePhotoProfile" id="file-upload" type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />
                            <span>** Ideal 600 x 450px (png , jpg)</span>
                        </div>
                        <label className="label">Tipo de establecimiento y/o servicio</label>
                        <Select
                            name="typestore"
                            value={typeStore}
                            className="select-input"
                            classNamePrefix="select-input"
                            options={[{value:"físico", label:"Físico"}, {value:"vitrual", label:"Vitrual"}, {value:"a domicilio", label:"A domicilio"}]}
                            placeholder=""
                            defaultValue={typeStore}
                            onChange={handleChangeTypeStore}
                        />
                        <label className="label">link de la web (opcional)</label>
                        <div className="input">
                            <input type="text" value={linkStore || ''} onChange={handleChangelinkStore} name="linkStore"/>
                        </div>
                        <label className="label">Galería (Opcionales)</label>
                        <span>** Colocar fotos rectangulares para una mejor visualización, tamaño máximo 2MB</span>
                        <br/>
                        <br/>
                        {imageUrl1 && <img
                            src={imageUrl1} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload-gallery-1" className="custom-file-upload1">{imageName1 && imageName1.substr(0,39)+'...'}<img src={upload}/></label>
                            <input name="chagePhotoProfile" id="file-upload-gallery-1" type="file" accept="image/*" onChange={handleFileChange1} style={{ display: 'none' }} />
                            {imageUrl1 &&  <img className="img-trash" src={trash} onClick={deleteImage1}/>}
                        </div>
                        {imageUrl2 && <img
                            src={imageUrl2} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload-gallery-2" className="custom-file-upload1">
                                {imageName2 && imageName2.substr(0,39)+'...'}
                                <img src={upload}/>
                            </label>
                            <input name="chagePhotoProfile" id="file-upload-gallery-2" type="file" accept="image/*" onChange={handleFileChange2} style={{ display: 'none' }} />
                            {imageUrl2 &&  <img className="img-trash" src={trash} onClick={deleteImage2}/>}
                        </div>
                        {imageUrl3 && <img
                            src={imageUrl3} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload-gallery-3" className="custom-file-upload1">
                                {imageName3 && imageName3.substr(0,39)+'...'}
                                <img src={upload}/>
                            </label>
                            <input name="chagePhotoProfile" id="file-upload-gallery-3" type="file" accept="image/*" onChange={handleFileChange3} style={{ display: 'none' }} />
                            {imageUrl3 &&  <img className="img-trash" src={trash} onClick={deleteImage3}/>}
                        </div>
                        {imageUrl4 && <img
                            src={imageUrl4} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload-gallery-4" className="custom-file-upload1">
                                {imageName4 && imageName4.substr(0,39)+'...'}
                                <img src={upload}/>
                            </label>
                            <input name="chagePhotoProfile" id="file-upload-gallery-4" type="file" accept="image/*" onChange={handleFileChange4} style={{ display: 'none' }} />
                            {imageUrl4 &&  <img className="img-trash" src={trash} onClick={deleteImage4}/>}
                        </div>
                        {imageUrl5 && <img
                            src={imageUrl5} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload-gallery-5" className="custom-file-upload1">
                                {imageName5 && imageName5.substr(0,39)+'...'}
                                <img src={upload}/>
                            </label>
                            <input name="chagePhotoProfile" id="file-upload-gallery-5" type="file" accept="image/*" onChange={handleFileChange5} style={{ display: 'none' }} />
                            {imageUrl5 &&  <img className="img-trash" src={trash} onClick={deleteImage5}/>}
                        </div>
                        {imageUrl6 && <img
                            src={imageUrl6} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload-gallery-6" className="custom-file-upload1">
                                {imageName6 && imageName6.substr(0,39)+'...'}
                                <img src={upload}/>
                            </label>
                            <input name="chagePhotoProfile" id="file-upload-gallery-6" type="file" accept="image/*" onChange={handleFileChange6} style={{ display: 'none' }} />
                            {imageUrl6 &&  <img className="img-trash" src={trash} onClick={deleteImage6}/>}
                        </div>
                        {imageUrl7 && <img
                            src={imageUrl7} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload-gallery-7" className="custom-file-upload1">
                                {imageName7 && imageName7.substr(0,39)+'...'}
                                <img src={upload}/>
                            </label>
                            <input name="chagePhotoProfile" id="file-upload-gallery-7" type="file" accept="image/*" onChange={handleFileChange7} style={{ display: 'none' }} />
                            {imageUrl7 &&  <img className="img-trash" src={trash} onClick={deleteImage7}/>}
                        </div>
                        {imageUrl8 && <img
                            src={imageUrl8} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload-gallery-8" className="custom-file-upload1">
                                {imageName8 && imageName8.substr(0,39)+'...'}
                                <img src={upload}/>
                            </label>
                            <input name="chagePhotoProfile" id="file-upload-gallery-8" type="file" accept="image/*" onChange={handleFileChange8} style={{ display: 'none' }} />
                            {imageUrl8 &&  <img className="img-trash" src={trash} onClick={deleteImage8}/>}
                        </div>
                        {imageUrl9 && <img
                            src={imageUrl9} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload-gallery-9" className="custom-file-upload1">
                                {imageName9 && imageName9.substr(0,39)+'...'}
                                <img src={upload}/>
                            </label>
                            <input name="chagePhotoProfile" id="file-upload-gallery-9" type="file" accept="image/*" onChange={handleFileChange9} style={{ display: 'none' }} />
                            {imageUrl9 &&  <img className="img-trash" src={trash} onClick={deleteImage9}/>}
                        </div>
                        {imageUrl10 && <img
                            src={imageUrl10} alt="firulife-service" onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                        />}
                        <div className="input">
                            <label htmlFor="file-upload-gallery-10" className="custom-file-upload1">
                                {imageName10 && imageName10.substr(0,39)+'...'}
                                <img src={upload}/>
                            </label>
                            <input name="chagePhotoProfile" id="file-upload-gallery-10" type="file" accept="image/*" onChange={handleFileChange10} style={{ display: 'none' }} />
                            {imageUrl10 &&  <img className="img-trash" src={trash} onClick={deleteImage10}/>}
                        </div>
                        <label className="label">Redes sociales (Opcionales)</label>
                        <div className="social">
                            <img src={facebook} alt="fb-firu"/>
                            <input type="text" value={fbStore || ''} placeholder="link de Facebook" onChange={handleChangefbStore} name="fbStore" />
                        </div>
                        <div className="social">
                            <img src={instagram} alt="fb-firu"/>
                            <input type="text" value={instragamStore || ''} placeholder="link de Instagram" onChange={handleChangeinstagramStore} name="instagramStore" />
                        </div>
                        <div className="social">
                            <img src={whatsapp} alt="fb-firu"/>
                            <input type="text" value={whatsappStore || ''} placeholder="Numero de whatsapp sin prefijo" onChange={handleChangewhatsappStore} name="whatsappStore" />
                        </div>
                        <label className="label">Servicio y/o características:</label>
                        <CreatableSelect
                            name="categories"
                            isMulti
                            value={tag}
                            className="select-input2"
                            classNamePrefix="select-input2"
                            options={tags}
                            defaultValue={tag}
                            placeholder=""
                            onChange={handleChangeTag}
                        />
                        <div className="border-bottom"></div>
                        <div className="subtitle">Ubicaciones</div>
                        <span>** Haz click en el mapa para agregar un marcador, y haz click en el marcador para eliminarlo del mapa</span>
                        <div className="block-ubigeo">
                            <div className="maps-content-service" tabIndex={-1}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
                                    defaultCenter={defaultProps.center}
                                    defaultZoom={defaultProps.zoom}
                                    options={{gestureHandling: 'greedy'}}
                                    center={userLocation || defaultProps.center}
                                    yesIWantToUseGoogleMapApiInternals
                                    onClick={handleMapClick}
                                    onChildClick={onChildClick}
                                >
                                    {markers.map((marker) => (
                                        <MarkerMap
                                            key={marker.id}
                                            id={marker.id}
                                            lat={marker.lat}
                                            lng={marker.lng}
                                        />
                                    ))}
                                    {
                                        userLocation &&
                                            <MyPositionMarker
                                                lat={userLocation.lat}
                                                lng={userLocation.lng}
                                            />
                                    }
                                </GoogleMapReact>
                            </div>
                        </div>
                        {markers.length> 0 && <div className="block-ubigeo">
                            <label className="label">Direcciones:</label>
                            {markers.map((marker) => (
                                <div className="input" key={marker.id}>
                                    <input type="text"
                                        name="addressStore"
                                        placeholder="direccion, distritito, ciudad"
                                        onChange={(event) => handleChangeAddress(event, marker.id)}
                                        value={marker.address || ''}
                                    />
                                </div>
                            ))}
                        </div>}
                        {
                            storeId &&
                            <div className="form-check form-switch">
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefaultAvailable">El servicio está disponible?</label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefaultAvailable"
                                    checked={isAvailable}
                                    onChange={handleSwitchChangeAvailable}
                                />
                            </div>
                        }
                        {
                            hasPermitions &&
                            <div className="form-check form-switch">
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefaultValidated">El servicio está validado?</label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefaultValidated"
                                    checked={isValidated}
                                    onChange={handleSwitchChangeValidated}
                                />
                            </div>
                        }
                        <button className="submit-profile" aria-label="save-services" type="button" onClick={saveStore}>Guardar</button>
                    </div>
                </form>
            </div>
            <ModalResponse message={message} url={"/dashboard/services"}/>
            <ToastModal message={messageToast}/>
            {
                loading && <Loading  isdashPage={isdashPage}/>
            }
        </div>
    );
}

export default NewServices;
